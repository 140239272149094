import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './Styles/ChapterPages.css';
import config from "../config";

const ChapterPages = () => {
  const { chapter_id } = useParams();
  const history = useNavigate();
  const [chapterTitle, setChapterTitle] = useState('');
  const [bookTitle, setBookTitle] = useState('');
  const [pages, setPages] = useState([]);
  const [resources, setResources] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [activeTab, setActiveTab] = useState('videos'); // Default active tab

  useEffect(() => {
    fetch(`${config.apiBaseUrl}/fullmarks-user/home/fetchchapterpages.php?chapter_id=${chapter_id}`)
      .then(response => response.json())
      .then(data => {
        if (data && data.length > 0) {
          setChapterTitle(data[0].chapter_name);
          setBookTitle(data[0].book_name);
          setPages(data);
        }
      })
      .catch(error => console.error('Error fetching chapter pages:', error));
  }, [chapter_id]);

  useEffect(() => {
    // Fetching resources for the chapter
    fetch(`${config.apiBaseUrl}/fullmarks-user/home/fetchResources.php?chapter_id=${chapter_id}`)
      .then(response => response.json())
      .then(data => setResources(data))
      .catch(error => console.error('Error fetching resources:', error));
  }, [chapter_id]);

  const handleNextPage = () => {
    if (currentPage < pages.length - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleFullView = () => {
    const fullViewUrl = `${config.apiBaseUrl}/admin/fullmarks-server/uploads/book_pages/${pages[currentPage].image_path}`;
    window.open(fullViewUrl, '_blank');
  };

  const handleShareChapter = () => {
    const shareUrl = window.location.href;
    navigator.clipboard.writeText(shareUrl).then(() => {
      alert('URL copied to clipboard!');
    }).catch((err) => {
      console.error('Failed to copy the URL: ', err);
    });
  };

  const renderResources = (type) => {
    const filteredResources = resources.filter(resource => resource.resource_type === type);
    if (filteredResources.length === 0) return <div>No {type} available.</div>;

    return (
<div className="video-container">
{filteredResources.map(resource => (
          <div key={resource.resource_id} className="mb-3">
                        <div className="fw-bold">{resource.resource_title}</div>

            {type === 'videos' && (

              <video className="zampa " controls>
                <source
                  src={`${config.apiBaseUrl}/admin/fullmarks-server/uploads/videos/${resource.video_file}`}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            )}
            {type === 'documents' && (
              <ul className= 'mithun'>
              <li className= ''>
              <a
                href={`${config.apiBaseUrl}/admin/fullmarks-server/uploads/documents/${resource.documents_file}`}
                target="_blank"
                rel="noopener noreferrer"
              >
               View
              </a>
              </li>
              </ul>
            )}
            {type === 'audio' && (
              <audio controls>
                <source
                  src={`${config.apiBaseUrl}/admin/fullmarks-server/uploads/audios/${resource.audio_file}`}
                  type="audio/mp3"
                />
                Your browser does not support the audio tag.
              </audio>
            )}
          {type === 'interactivities' && (
              
              <a
                href={`${config.apiBaseUrl}/admin/fullmarks-server/uploads/interactivities/${resource.interactivities_file}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                View
              </a>
             
            )}

{type === 'addnworksheets' && (
              
              <a
                href={`${config.apiBaseUrl}/admin/fullmarks-server/uploads/addnworksheets/${resource.addnworksheets_file}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                View
              </a>
             
            )}
            {type === 'addnactivities' && (
              
              <a
                href={`${config.apiBaseUrl}/admin/fullmarks-server/uploads/addnactivities/${resource.addnactivities_file}`}
                target="_blank"
                rel="noopener noreferrer"
              >
               View
              </a>
             
            )}

{type === 'answerkeys' && (
              
              <a
                href={`${config.apiBaseUrl}/admin/fullmarks-server/uploads/answerkeys/${resource.answerkeys_file}`}
                target="_blank"
                rel="noopener noreferrer"
              >
               View
              </a>
             
            )}


          </div>
        ))}
      </div>
    );
  };

  return (
    <div>
    <div className="mt-5 container bg-white mb-5 " style= {{boxShadow: "0px 0px 5px lightgrey"}}>
      <div className='border diskcha'>
        <p className='text-center h2 mt-4 text-uppercase fw-bold text-white'>
          {bookTitle} / {chapterTitle}
        </p>
      </div>
      <div className='d-flex justify-content-between fw-bold bg-light'>
        <div>
        <i class="bi bi-arrow-left"></i>{chapterTitle}
        </div>
        <div>
          <i className="bi bi-chevron-double-left fw-bold" onClick={handlePrevPage}></i>
          <i className="bi bi-chevron-double-right mx-3" onClick={handleNextPage}></i>
          <i className="mx-2 bi bi-fullscreen" onClick={handleFullView}></i>
          <i className="bi bi-share-fill mx-2 " onClick={handleShareChapter}></i>
      </div>
      </div>
      <hr />

      <div className="page-navigation-container p-3">
        <div className="page-content">
          {pages.length > 0 ? (
            <div className="border">
              <img
                src={`${config.apiBaseUrl}/admin/fullmarks-server/uploads/book_pages/${pages[currentPage].image_path}`}
                alt={`Page ${currentPage + 1}`}
                className="page-image p-3"
              />
            </div>
          ) : (
            <div className="no-pages-found">No pages found</div>
          )}
        </div>
      </div>

      <div className="d-flex justify-content-center">
        {currentPage > 0 && (
          <span className="nav-arrow left-arrow" onClick={handlePrevPage}>
            <i className="bi bi-arrow-left-square-fill"></i>
          </span>
        )}
        {currentPage < pages.length - 1 && (
          <span className="nav-arrow right-arrow" onClick={handleNextPage}>
            <i className="bi bi-arrow-right-square-fill"></i>
          </span>
        )}
      </div>

      
    </div>
    <div className="p-5 m-5 bg-white resources-container  " style= {{boxShadow: "0px 0px 5px lightgrey"}}>
    <h3 className= 'bg-warning p-2'>Resources for this Chapter</h3>
    <ul className="nav nav-tabs ">
      <li className="nav-item ">
        <button
          className={`nav-link ${activeTab === 'videos' ? 'active' : ''}`}
          onClick={() => setActiveTab('videos')}
        >
          Videos
        </button>
      </li>
      <li className="nav-item">
        <button
          className={`nav-link ${activeTab === 'audio' ? 'active' : ''}`}
          onClick={() => setActiveTab('audio')}
        >
          Audio
        </button>
      </li>
      <li className="nav-item">
        <button
          className={`nav-link ${activeTab === 'documents' ? 'active' : ''}`}
          onClick={() => setActiveTab('documents')}
        >
          Documents
        </button>
      </li>
      <li className="nav-item">
        <button
          className={`nav-link ${activeTab === 'interactivities' ? 'active' : ''}`}
          onClick={() => setActiveTab('interactivities')}
        >
          Interactivities
        </button>
      </li>
      <li className="nav-item">
        <button
          className={`nav-link ${activeTab === 'addnworksheets' ? 'active' : ''}`}
          onClick={() => setActiveTab('addnworksheets')}
        >
          Worksheets
        </button>
      </li>
      <li className="nav-item">
        <button
          className={`nav-link ${activeTab === 'addnactivities' ? 'active' : ''}`}
          onClick={() => setActiveTab('addnactivities')}
        >
          Additional Activities
        </button>
      </li>
      <li className="nav-item">
        <button
          className={`nav-link ${activeTab === 'answerkeys' ? 'active' : ''}`}
          onClick={() => setActiveTab('answerkeys')}
        >
          Answer Keys
        </button>
      </li>
    </ul>

    <div className="tab-content mt-4 ">
      {activeTab === 'videos' && renderResources('videos')}
      {activeTab === 'audio' && renderResources('audio')}
      {activeTab === 'documents' && renderResources('documents')}
      {activeTab === 'interactivities' && renderResources('interactivities')}
      {activeTab === 'addnworksheets' && renderResources('addnworksheets')}
      {activeTab === 'addnactivities' && renderResources('addnactivities')}
      {activeTab === 'answerkeys' && renderResources('answerkeys')}
    </div>
  </div>
  </div>
  );
};

export default ChapterPages;
