import React, { useEffect, useState, useContext } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import './Styles/BookDetails.css';
import config from "../config";
import AuthContext from '../Components/Access/AuthContext';
import LoginRequiredModal from '../Components/Access/LoginRequiredModal';
import share from "./Assets/share.png"
import { Dropdown } from 'react-bootstrap'; // Import Bootstrap Dropdown

import { decodeId } from '../Components/Access/EncodeDecode';

const BookDetails = () => {
  const { book_id } = useParams();
  const decodedId = decodeId(book_id); // Decode the ID for internal use
  const [book, setBook] = useState({});
  const [allpages, setAllpages] = useState([]); // Storing pages as an array
  const [chapters, setChapters] = useState([]);
  const [showLoginModal, setShowLoginModal] = useState(false); // State for showing the modal
  const { user } = useContext(AuthContext); // Get the user from the AuthContext
  const navigate = useNavigate(); // Get the navigate function from react-router-dom

  useEffect(() => {
    fetch(`${config.apiBaseUrl}/fullmarks-user/navbar/fetchbookdetails.php?book_id=${decodedId}`)
      .then(response => response.json())
      .then(data => setBook(data))
      .catch(error => console.error('Error fetching book details:', error));

    fetch(`${config.apiBaseUrl}/fullmarks-user/navbar/fetchchapters.php?book_id=${decodedId}`)
      .then(response => response.json())
      .then(data => setChapters(data))
      .catch(error => console.error('Error fetching chapters:', error));
  }, [book_id]);

  const handleChapterClick = (chapterId) => {
    if (user) {
    } else {
      setShowLoginModal(true);
    }
  };

  // Handle the View Book button click
  const handleAllpageClick = (bookId) => {
    if (user) {
      // Ensure pages have been fetched before navigating
      if (allpages && allpages.length > 0) {
      } else {
        alert('No pages available for this book!');
      }
    } else {
      setShowLoginModal(true);
    }
  };

  // Handle Share Book
  const handleShareClick = () => {
    navigator.clipboard.writeText(window.location.href);
    alert('Link copied to clipboard!');
  };

  return (
    <div className="mt-5 bg-white p-3 mb-5 rounded container" style= {{boxShadow: "0px 0px 7px lightgrey"}}>
     
      <div className="row">
      <div className= 'border diskcha'>
      <p className= 'text-center h2 mt-4 text-uppercase fw-bold text-white'>{book.book_name}/ {book.class_name}/ {book.subject_name}</p>
      </div> 
        <div className="col-md-4 d-flex flex-column align-items-center">
        {book.book_cover && (
            <img src={`${config.apiBaseUrl}/admin/fullmarks-server/uploads/book_cover/${book.book_cover}`} alt="Book Cover" className="book-cover img-fluid rounded" />
          )}

</div>

        <div className='col-md-1'></div>

        
          
      
        <div className="col-md-7 bg-light" style= {{boxShadow: "0px 0px 5px lightgrey"}}>
          <div className='d-flex justify-content-between mt-2'>
            <h4 className="fw-bold">{book.book_name}</h4>
            <div>
            <Dropdown className="mt-2">
            <Dropdown.Toggle  id="dropdown-basic">
              <i className="bi bi-three-dots-vertical"></i> {/* Menu Icon */}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => handleAllpageClick(book_id)}>
                <i className="bi bi-eye-fill"></i> View Book
              </Dropdown.Item>
              <Dropdown.Item >
                <i className="bi bi-file-earmark-arrow-down"></i> Download Book
              </Dropdown.Item>
              <Dropdown.Item>
                <i className="bi bi-file-arrow-down-fill"></i> Download TPG
              </Dropdown.Item>
              <Dropdown.Item onClick={handleShareClick}>
                <i className="bi bi-share"></i> Share Book
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
            </div>
          </div>
          <p className="text-grey fst-italic pre-wrap">{book.book_description}</p>
          <hr />
          <div className='fw-bold text-center h5 text-underline'>Chapters:</div>
          <div className='mt-4 bg-white ' >
            <ol>
             
                      {chapters.map(chapter => (
                <div key={chapter.chapter_id}>
                      <tr>
                    
                        <li className='mt-2 challa' onClick={() => handleChapterClick(chapter.chapter_id)}>
                    {chapter.chapter_title} 
                    
                  </li> 
                 
                                          
                  </tr>
                  </div>
              ))}
                 
                 
                
            </ol>
          </div>
        </div>
</div>
      <LoginRequiredModal show={showLoginModal} handleClose={() => setShowLoginModal(false)} />
    </div>
  );
}

export default BookDetails;
